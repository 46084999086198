'use strict';

window.addEventListener('load', function () {
    $(':button.needsclick').on('click', function () {
        var email = $(':input.needsclick').val();
        var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
        if (email && regex.test(email) === true) {
            swellAPI.captureNewsletterSignup(email);
            window.console.log('Newsletter capture done.');
        }
    });

    var reviewText = document.querySelectorAll('.product-tile .text-m');
    if (reviewText) {
        reviewText.forEach((review) => {
            review.innerText = '(' + review.innerText.split(' ')[0] + ')';
        });
    }
});
